import React, { useState } from 'react'
import {
    Tabs, TabList, TabPanels, Tab, TabPanel,Button,
    Box,
    Flex
} from '@chakra-ui/react'
import MailConfiguration from './MailConfiguration'
import Campaigns from './Campaigns'

const UsersListTab = () => {
    const [activeIndex,setActiveIndex] = useState(1)
    const handleChange = (index) => {
        setActiveIndex(index)
    }
    return (
        
        <Tabs isFitted isLazy variant='enclosed-colored'>
        <TabList mb='1em'>
          <Tab>Users List</Tab>
          <Tab>Mail Configuration</Tab>
        </TabList>
        <Box boxShadow={'md'} mt={2}>
        <TabPanels>
          <TabPanel>
            <Campaigns />
          </TabPanel>
          <TabPanel>
           <MailConfiguration />
          </TabPanel>
        </TabPanels>
        </Box>
      </Tabs>
    );
}

export default UsersListTab;