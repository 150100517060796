import React from 'react'
import { Box,Text,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Button,
    Flex,
    useToast,
    Spinner
} from '@chakra-ui/react'
import { FiEdit2,FiDownload,FiCopy } from 'react-icons/fi'
import axios from 'axios'
import { QRCodeCanvas } from 'qrcode.react';
import { BASEURL } from './../../api/index'
import { useLocation,useHistory, Link } from 'react-router-dom'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { auth } from '../../api/firebase-config';
import { onAuthStateChanged } from '@firebase/auth'
const Analytics = () => {

        const toast = useToast()

        const location = useLocation()
        const history = useHistory()
        const [campaignsList,setCampaingsList] = React.useState([])
        const [totalAnalytics,setTotalAnalytics] = React.useState({})
        const [areCampaignsFetched,setAreCampaingsFetched] = React.useState(false)
        const [user,setUser] = React.useState(null)
        const [isFetching,setIsFetching] = React.useState(false)
        const [noMoreCampaigns,setNoMoreCampaigns] = React.useState(false)

        const showToast = (title,description,status,duration) => {
            toast({
                title,
                description,
                status,
                duration,
                isClosable: true,
            })
        }

        const downloadQR = (id) => {
            const canvas = document.getElementById(id);
            const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
            let downloadLink = document.createElement("a");
            downloadLink.href = pngUrl;
            downloadLink.download = `${id}.png`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          };

        const DynamicTr = ({campaignData}) => {
            return <>
             <Tr>
        <Td maxW={170} overflowX='auto' p={0} wordBreak='break-word'>
        <Text textAlign='center' fontSize={17} fontWeight='semibold' as='span'>
           {campaignData['campaign_name']}
        </Text>
        </Td>
        <Td>
        <Text textAlign='center' fontSize={17} fontWeight='semibold'>
            {campaignData['campaign_type']}
        </Text>
        </Td>
        <Td isNumeric>
            <Text textAlign='center' fontSize={17} fontWeight='semibold'>
            {campaignData['scans_number']}
            </Text>
        </Td>
        <Td isNumeric h={'100%'}>
            <Flex direction={'column'} justify='center' align='center'>

            <Text textAlign='center' fontSize={17} fontWeight='semibold'>
            {campaignData['submissionsNumber']}
            </Text>
            <Button colorScheme={'purple'} variant={'ghost'} onClick={()=>redirectToSubmissionsDetails(campaignData['campaign_id'])}>
                <Text>Details</Text>
            </Button>
            </Flex>
        </Td>
        <Td>
            <Text textAlign={'center'}>
                <Link to={`/dashboard/edit-campaign/${campaignData['campaign_id']}`}>
                <FiEdit2 size={22} />
                </Link>
            </Text>
        </Td>
        <Td display='flex' flexDirection='column' alignItems='center'>
            
        <QRCodeCanvas value={`${window.location.origin}/c/${campaignData['campaign_id']}`} size={76.3} id={campaignData['campaign_id']}/>
        <Flex>
             <Button variant={'ghost'} mt={1.5} onClick={()=>downloadQR(campaignData['campaign_id'])}>
                <FiDownload size={22}/>
            </Button>
            <CopyToClipboard text={`${window.location.origin}/c/${campaignData['campaign_id']}`} size={76.3}
          onCopy={() => showToast('Copied to clipboard.','','success',1500)}>
            <Button variant={'ghost'} mt={1.5} px={2}>
                <FiCopy size={22}/>
            </Button>
        </CopyToClipboard>
        </Flex>
        </Td>
        </Tr>
            </>
        }
        const fetchCampaigns = () => {
            setIsFetching(true)
            axios.get(`${BASEURL}/campaigns/all-compaigns`,{
                params : {
                    campaignsListLength:campaignsList.length,
                    accessToken:user.accessToken
                }
            })
            .then(res=>{
                // console.log('total analytics',res.data)
                if(campaignsList.length > 0 && res.data.results.campaignsListAnalytics == 0) setNoMoreCampaigns(true)
                setCampaingsList([...campaignsList,...res.data.results.campaignsListAnalytics])
                setTotalAnalytics(res.data.results.totalAnalytics)
                setAreCampaingsFetched(true)
            }).then(()=>setIsFetching(false)).catch(err=>{
                showToast('Error','Unable to retrieve Campaigns, Please Try Again!','error',3000)
                console.log('fetch campaigns error',err)
            })
        }
        const redirectToSubmissionsDetails = id => {
            history.push(`/dashboard/submissions/${id}`)
        }
    
    const Loading = () => {
        return <Flex align='center' justify={'center'} p={5}>
        <Spinner />                        
        </Flex>
    }

        onAuthStateChanged(auth,currentUser=>{
            setUser(currentUser)
        })
    React.useEffect(()=>{
        if(user) fetchCampaigns()
    },[user])

    return (
        <Box>
          
           <Box
            boxShadow={'md'}
            py={4}
            px={3} 
            >
                 {areCampaignsFetched == false ? <Loading /> : <>
                <Text 
                fontSize={32}
                fontWeight='semibold'
                >Campaigns List</Text>
               {campaignsList.length == 0 ? <Text>No Campaigns!</Text> : <Box>
                    <Text fontWeight='semibold'>Total Scans : {totalAnalytics['TotalScans']}</Text>
                    <Text fontWeight='semibold'>Total Submissions : {totalAnalytics['TotalSubmissions']} </Text>
                </Box>}
                 {/* Table Analytics */}
           {campaignsList.length != 0 &&  <Box my={4} >
            <TableContainer overflow={{lg:'hidden'}}>
  <Table variant='simple' size="lg" >
    {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
    <Thead>
      <Tr>
        <Th>
            <Text textAlign='center' fontWeight='semibold' fontSize={16}>Name</Text>
        </Th>
        <Th>
            <Text textAlign='center' fontWeight='semibold' fontSize={16}>Type</Text>
        </Th>
        <Th>
        <Text textAlign='center' fontWeight='semibold' fontSize={16}> Scans Number</Text>
        </Th>
        <Th>
        <Text textAlign='center' fontWeight='semibold' fontSize={16}>Submissions Number</Text>
        </Th>
        <Th>
        <Text textAlign='center' fontWeight='semibold' fontSize={16}>Edit</Text>
        </Th>
        <Th>
        <Text textAlign='center' fontWeight='semibold' fontSize={16}>QR Code</Text>
        </Th>
      </Tr>
    </Thead>
    <Tbody>
        {(campaignsList.length != 0) && campaignsList.map(campaignData=>{
            return <DynamicTr campaignData={campaignData}/>
        })}
    </Tbody>
  </Table>
</TableContainer>
        {(noMoreCampaigns == false) && <Box mt={4} display={'flex'} justifyContent='center' alignItems={'center'}>
            <Button
            isLoading={isFetching}
            loadingText='Loading'
            colorScheme='purple'
            variant='outline'
            spinnerPlacement='start'
            size='lg'
            onClick={()=>fetchCampaigns()}
            >Load More</Button>
        </Box>}

            </Box>}
            </> }
            </Box>      
        </Box>
    );
}
 
export default Analytics;