import React from "react";
import {Helmet} from "react-helmet";
import { useLocation } from "react-router-dom";
const Metadata = ({title}) => {
    const location = useLocation()
    return (
        <div className="application">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{title}</title>
                {location == '/admin-dashboard' && <meta name="robots" content="noindex, nofollow" />}
            </Helmet>
        </div>
        )
}
 
export default Metadata;